<template>
  <div id="cms-page">
    <div class="header-box py35 pl20">
      <div class="container">
        <breadcrumbs
          :with-homepage="true"
          :routes="[]"
          :active-route="cmsPageContent.title"
        />
        <h1 class="fs-big title-text cl-black mb0 align-center">
          {{ cmsPageContent.title }}
        </h1>
      </div>
    </div>
    <div class="container pt45 pb70 static-box">
      <div class="row pl20 pt0">
        <div class="col-sm-3 hidden-xs">
          <nav class="static-menu serif h4 mb35">
            <ul class="m0 p0">
              <li class="mb10" v-for="page in navigation" :key="page.id">
                <router-link :to="localizedRoute(page.link)" class="cl-accent relative">
                  {{ page.title }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="static-content h4 lh35 col-md-9 col-xs-12">
          <div class="cms-content" v-html="cmsPageContent.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CmsPage from '@vue-storefront/core/pages/CmsPage'
import i18n from '@vue-storefront/i18n'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  components: {
    Breadcrumbs
  },
  data () {
    return {
      navigation: [
        { title: i18n.t('Polityka prywatności'), link: i18n.t('/i/polityka-prywatnosci') },
        { title: i18n.t('Regulamin serwisu'), link: i18n.t('/i/regulamin-serwisu') },
        { title: i18n.t('Nasz asortyment'), link: i18n.t('/i/nasz-asortyment') },
        { title: i18n.t('Dla Architektów'), link: i18n.t('/i/dla-architektow') },
        { title: i18n.t('Koszty dostawy'), link: i18n.t('/i/koszty-dostawy') },
        { title: i18n.t('Formy płatności'), link: i18n.t('/i/formy-platnosci') },
        { title: i18n.t('Zwroty i reklamacje'), link: i18n.t('/i/zwroty-i-reklamacje') },
        { title: i18n.t('Interaktywne Katalogi'), link: i18n.t('/i/interaktywne-katalogi') },
        { title: i18n.t('Wirtualny spacer'), link: i18n.t('/i/wirtualny-spacer') },
        { title: i18n.t('Salony'), link: i18n.t('/i/salony') },
        { title: i18n.t('Tax free'), link: i18n.t('/i/tax-free') },
        { title: i18n.t('Wycena łazienki'), link: i18n.t('/i/wycena-lazienki') },
        { title: i18n.t('Dane kontaktowe'), link: i18n.t('/i/kontakt') }
      ]
    }
  },
  computed: {
    cmsPageContent () {
      return this.$store.state.cmsPage.current
    }
  },
  mixins: [CmsPage],
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.static-box {
  min-height: calc(100vh - 702px);
}
.title-text {
  // margin: 0;
}
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$border-primary: color(primary, $colors-border);
.static-menu {
  ul {
    list-style: none;
  }
  a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $border-primary;
  }
  a:hover::after,
  .router-link-active::after {
    opacity: 0;
  }
}
.static-content {
  *:first-of-type {
    margin-top: 0;
  }
}
.static-content {
  h3 {
    margin-top: 40px;
    margin-bottom: 25px;
    @media (max-width: 767px) {
      margin-top: 35px;
      margin-bottom: 10px;
    }
  }
}
.static-box {
  @media (max-width: 767px) {
    padding-top: 0;
  }
}
</style>
